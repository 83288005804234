<form [formGroup]="vLanAndClusterForm">
    <div class="row isr">
        <div class="col-lg-6">
            <div class="card card-primary">
                <div class="card-header">
                    <div class="card-title">VLAN and Cluster</div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block">
                    <div class="row">
                        <div class="col-lg-11">
                            <!-- <label style="font-weight:bolder;">Project reference and budget</label> -->
                        </div>
                        <div class="col-lg-1">
                            <!-- <img style="padding-bottom: 5px;" title="{{vLaAndClusterNote}}"
                                src="assets/Images/info.png"> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-5">
                            <label>Select the cluster using:</label>
                        </div>
                        <div class="col-lg-7">
                            <input type="radio" formControlName="isCluster" value="1"
                                [ngClass]="{ 'is-invalid': submitted && validated.isCluster.errors }"
                                (change)="ConditionalValidator()">
                            &nbsp;<label>Cluster Name</label>&nbsp;&nbsp;
                            <input type="radio" formControlName="isCluster" value="2"
                                [ngClass]="{ 'is-invalid': submitted && validated.isCluster.errors }"
                                (change)="ChangeisVlanOrCluster()">
                            &nbsp;<label>Rackmount/Enclosure</label>&nbsp;
                        </div>
                    </div>
                    <div class="row" *ngIf="vLanAndClusterForm.value.isCluster=='1'">
                        <div class=" col-lg-5">
                            <label>Cluster name:</label>
                        </div>
                        <div class="col-lg-7">
                            <mat-form-field class="example-full-width">
                                <mat-label>Cluster</mat-label>
                                <input type="text" matInput [formControl]="clusterControl" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                                    (optionSelected)="selectClusterOption($event)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ option.clusterName }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="vLanAndClusterForm.value.isCluster=='2'">
                        <div class=" col-lg-5">
                            <label>Rackmount/Enclosure:</label>
                        </div>
                        <div class="col-lg-7">
                            <mat-form-field class="example-full-width">
                                <mat-label>Rackmount/Enclosure</mat-label>
                                <input type="text" matInput [formControl]="rackmountControl" [matAutocomplete]="auto">
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn2"
                                    (optionSelected)="selectRackmountOption($event)">
                                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                        {{ option.rackmountEnclosure }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row" *ngIf="vLanAndClusterForm.value.isCluster=='2' && selectedClusterName!=''">
                        <div class=" col-lg-12" style="font-size: 14px;text-align: center;">
                            This rackmount/enclosure belongs to cluster {{selectedClusterName}}
                        </div>
                    </div>
                    <div class="row"
                        *ngIf="vLanAndClusterForm.value.isCluster=='1' || vLanAndClusterForm.value.isCluster=='2'">
                        <div class=" col-lg-5">
                            <label>VLAN numbers:</label>
                        </div>                        
                        <div class="col-lg-6">
                            <input type="text" formControlName="vlanNumbers"  name="vlanNumbers"
                                [ngClass]="{ 'is-invalid': submitted && validated.vlanNumbers.errors }">
                        </div>                        
                        <div class="col-lg-1">
                            <img style="padding-bottom: 5px;" title="{{vLanAndClusterNote}}"
                                src="assets/Images/info.png">
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card-primary">
                <div class="card-block">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="submit" class="button-design" (click)="GoToPreviousView()">Previous</button>
                        </div>
                        <div class="col-lg-6" style="text-align: right;">
                            <button type="submit" class="button-design" (click)="GoToNextView()">Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div style="height: 40px;"></div>
            <div class="row">
                <div *ngIf="submitted && validated.isCluster.errors" class="ValidationLabel">
                    <div *ngIf="validated.isCluster.errors.required">Please select is this request part of a project?.
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="submitted && vLanAndClusterForm.value.isCluster=='1' && selectedClusterName==''"
                    class="ValidationLabel">
                    <div>Please select cluster name</div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="submitted && vLanAndClusterForm.value.isCluster=='2' && selectedRackmountName==''"
                    class="ValidationLabel">
                    <div>Please select Rackmount/Enclosure</div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="submitted && validated.vlanNumbers.errors?.required"
                    class="ValidationLabel">
                    <div>Please provide VLAN numbers</div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="submitted && validated.vlanNumbers.errors?.pattern && validated.vlanNumbers.touched"
                    class="ValidationLabel">
                    <div>Invalid VLAN numbers</div>
                </div>
            </div>
        </div>
    </div>
</form>