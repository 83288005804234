import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from '../Shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class VlanAndClusterService {

  constructor(private _http: HttpClient, private sharedService: SharedService) { }


  GetVMClusters(): Promise<any> {
    return this._http.get<any[]>(this.sharedService.API_URL + '/api/ISR/request/GetVMClusters').toPromise();
  };
}
