<form novalidate [formGroup]="sendRequestForm">
    
    <div class="row isr" >       
            <div class="col-lg-8">
                <ol class="no-dot">
            <div class="card card-primary">
                <div class="card-header1">
                    <div class="card-title">{{requestType}}</div>
                    <div *ngIf="showSubTypeOfRequest">{{requestSubType}}</div>
                    <!--<div class="card-title">{{requestType}}</div>-->
                    <!--<div class="card-title">{{requestSubType}}</div>-->
                </div>
            </div>
            <div class="card card-primary" *ngIf="isForm">
                <div class="card-block"> 
                    <div class="row">                       
                        <div class="col-lg-6"> 
                            <div class="row">    
                                <div class="col-lg-1" style="align-content:flex-start ;">                     
                                    <span class="circle" ><li></li></span></div>  
                            <div class="col-lg-11">                                 
                            <label>Download the template and save it locally:</label>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-2">
                            <button type="submit" style="padding: 2px;" class="button-design" (click)="GetTemplate()">Download Template</button>
                    </div>
                    <div class="col-lg-1" style="padding-top: 2px;">
                        <custom-tooltip [template]="TooltipContent">
                            <img src="assets/Images/info.png" />
                           <!-- <span>
                              <strong>content with tooltip</strong>
                            </span>-->
                          </custom-tooltip>
                       <!--<div contenttype="template" style="margin-top: 6px;" matTooltip=          
                        "Please note that incomplete templates will be rejected.
                        <br><br>After pressing the 'Download template' 
                        button you must open or save the template to your local drive (or desktop).
                        <br><font color='red'>
                            Watch your screen carefully: An extra protection message might be shown on the top or bottom of the page.</font> <br><br>When having completely filled the template do not forget to SAVE IT! Remember where you have saved it, so you can browse & upload it in the next step." matTooltipPosition="after">
              <img src="assets/Images/info.png" />  </div>-->
             <!-- <div contenttype="template" style="margin-top: 6px;" [matTooltip]="tooltipHtml"
              matTooltipPosition="above">-->
              <!--<div [matTooltip]="tooltipTemplate"
  matTooltipClass="custom-tooltip" 
  style="margin-top: 6px;" >-->
  <!-- new <div (mouseenter)="showTooltip($event)" (mouseleave)="hideTooltip()"  >
              <img src="assets/Images/info.png" />
            </div> 
              <ng-template #tooltipTemplate>
                <app-custom-tooltip [content]="tooltipContent"></app-custom-tooltip>
              </ng-template>-->
           <!--  <img src="assets/Images/info.png" 
     [matTooltip]="tooltipHtml"
     matTooltipPosition="above">-->
           
                </div>
                <div class="col-lg-3" style="padding-top: 2px;">
                    <label style="color: blue;">Template date: {{tempdateDate}}</label>
            </div>
            
                    </div>                     
                </div>                       
                </div>
                <div class="card card-primary" *ngIf="isForm">
                    <div class="card-block"> 
                        <div class="row">

                            <div class="col-lg-7">
                                <div class="row">
                                    <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                            <div class="col-lg-11">
                                <div class="row">
                                    <div class="col-lg-12" style="padding-left: 9px;">
                                    <label>Open the locally saved template and fill in all fields.</label>
                                </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12" style="padding-left: 9px;">
                                    <label>Make sure you have answered all questions completely.</label>
                                </div>                                
                                </div>
                                <div class="row">
                                    <div class="col-lg-12" style="padding-left: 9px;">
                                    <label>The document now has become your request form.</label>
                                </div>                                
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                    <label style="font-weight:800;">Save the form.</label>
                                </div>
                                </div>                                
                            </div>   

                        </div>
                            </div>

                        </div>                     
                    </div>                       
                    </div>                    
                    <div class="card card-primary" *ngIf="isForm">
                        <div class="card-block">                            
                            <div class="row">
                                <div class="col-lg-6">    
                                    <div class="row">                         
                                <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                                <div class="col-lg-11">
                                    <label>Specify the form to be uploaded:</label>
                                </div>
                            </div>     
                                </div>                            
                            </div>        
                            <div class="row">
                                <div style="margin-left: 30px;" class="col-lg-12">
                                    <div class="row">
                                <div class="col-lg-8" title="{{uploadAlertMsg}}">
                                    <div class="card card-primary">
                                        <div class="card-block">
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <input type="file" accept='.xls,.xlsx,.doc,.docx,zip' (change)="handleFileInput($event.target.files)" formControlName="templateFile" >                                                   
                                                </div>                                               
                                            </div>                                                                                 
                                        </div>
                                    </div>
                                </div> 
                                <div class="col-lg-3">
                                    <div style="font-size: 12px;" [innerHTML]="uploadHtmlcontent"></div>
                                </div>  
                                </div>   
                                </div>                                                  
                            </div>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div *ngIf="submitted && validated.templateFile.errors" class="ValidationLabel">
                                        <div *ngIf="validated.templateFile.errors.required">Template File is required.</div>
                                       </div> 
                                </div>                               
                            </div>   
                            <div class="row" *ngIf="this.isWrongFile">
                                <div class="col-lg-12" class="ValidationLabel">
                                    <div style="margin-left: 30px;">Uploaded file extension should be same as template file extension.</div>                                                   
                                </div>                                               
                            </div>           
                        </div>                       
                        </div>
                        <div class="card card-primary" *ngIf="isForm">
                            <div class="card-block"> 
                                <div class="row">   
                                    <div class="col-lg-5"> 
                                        <div class="row">                                
                                    <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                                    <div class="col-lg-9">
                                        <label>You can/may supply additional information (this is optional):</label>
                                    </div>  
                                    </div>
                                </div>
                                    <div class="col-lg-7">
                                        <textarea formControlName="addInfo" 
                                        maxlength="3999" placeholder="max. 3999 chars"
                                        style="font-size: 14px; width: 90%; height: 100px;"></textarea>
                                    </div>                             
                                </div>   
                            </div>
                        </div>

                        <div class="card card-primary" *ngIf="!isForm">
                            <div class="card-block"> 
                                <div class="row">   
                                    <div class="col-lg-4"> 
                                        <div class="row">                                
                                    <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                                    <div class="col-lg-10">
                                        <label>Request (max.: 3999 chars):</label>
                                    </div>  
                                    </div>
                                </div>
                                    <div class="col-lg-8">
                                        <textarea formControlName="longDescription" 
                                        placeholder={{reqDefinitionModel.waterMark}}
                                        style="font-size: 14px; width: 90%; height: 100px;"                                        
                                        maxlength="3999" [readOnly]="isReadOnly" [className]="isReadOnly ? 'radonly_color' : 'other-class'"></textarea>
                                    </div>                             
                                </div>   
                            </div>
                        </div>
                        <div class="card card-primary" *ngIf="DoAttach">
                            <div class="card-block">                            
                                <div class="row">
                                    <div class="col-lg-6">    
                                        <div class="row">                         
                                    <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                                    <div class="col-lg-11">
                                        <label>Attach a file:</label>
                                    </div>
                                </div>     
                                    </div>                            
                                </div>        
                                <div class="row">
                                    <div style="margin-left: 30px;" class="col-lg-12">
                                        <div class="row">
                                    <div class="col-lg-8" title="{{uploadAlertMsg}}">
                                        <div class="card card-primary">
                                            <div class="card-block">
                                                <div class="row">
                                                    <div class="col-lg-12">
                                                        <input type="file" (change)="attachFileInput($event.target.files)" formControlName="attchaFile" >                                                   
                                                    </div>                                               
                                                </div>                                                                                 
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="col-lg-3">
                                        <div style="font-size: 12px;" [innerHTML]="uploadHtmlcontent"></div>
                                    </div>  
                                    </div>   
                                    </div>                                                  
                                </div>
    
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div *ngIf="submitted && validated.templateFile.errors" class="ValidationLabel">
                                            <div *ngIf="validated.templateFile.errors.required">Template File is required.</div>
                                           </div> 
                                    </div>                               
                                </div>   
                                <div class="row" *ngIf="this.isWrongFile">
                                    <div class="col-lg-12" class="ValidationLabel">
                                        <div style="margin-left: 30px;">Uploaded file extension should be same as template file extension.</div>                                                   
                                    </div>                                               
                                </div>           
                            </div>                       
                            </div>
                            
                        <div *ngIf="showApprovers" >                        
                            <app-set-approver  (manager)="getManager($event)" (managerEmail)="getManagerEmail($event)" [userName]="userName" [devMode]="devMode" [approverListOnly]="approverListOnly"  ></app-set-approver>
                        </div>
                        <div class="card card-primary">
                            <div class="card-block"> 
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="row">
                                    <div class="col-lg-1" style="align-content:flex-start ;"> <span class="circle"><li></li></span></div>
                                    <div class="col-lg-10">
                                        <label>Send the request:</label>
                                    </div>
                                    </div>
                                </div>
                                    <div class="col-lg-2">
                                        <button type="submit" class="button-design" (click)="SendRequest()" >Send</button>
                                    </div>
                                    
                                </div>                                
                            </div>                       
                            </div>
                        </ol>
            </div>        
    </div>

    <ng-template #TooltipContent>
        <div >
            <div>Please note that incomplete templates will be rejected.</div>
            <div><br>After pressing the 'Download template' button you must 
                open or save the template to your local drive (or desktop).<br></div>
                <div style="color: red;"> Watch your screen carefully
                 An extra protection message might be shown on the top or bottom of the page.</div>
                  <div ><br>When having completely filled the template do not forget to SAVE IT! 
                  Remember where you have saved it, so you can browse & upload it in the next step.
                  </div>
        </div>
      </ng-template>   
    </form>