import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SharedService } from '../Shared/shared.service';
import { RequestModel, serviceModel } from '../model/request.model';
import { RequestDefinitionsModel } from '../model/requestDefinitions.model';
import { LoginModel } from '../model/login.model';
import { userModel } from '../model/user.model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ClustersModel } from '../model/clusters.model';
import { VlanAndClusterService } from './vlan-and-cluster.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-vlan-and-cluster',
  templateUrl: './vlan-and-cluster.component.html',
  styleUrls: ['./vlan-and-cluster.component.css'],
  providers: [RequestModel, RequestDefinitionsModel, userModel, LoginModel, serviceModel, ClustersModel]
})
export class VlanAndClusterComponent implements OnInit, AfterViewInit {
  vLanAndClusterForm: UntypedFormGroup;
  vLanAndClusterNote: string = "";
  submitted = false;
  selectedClusterName = "";
  selectedRackmountName = "";
  vLanNumbers = '';
  clusterControl = new FormControl<string | ClustersModel>('');
  rackmountControl = new FormControl<string | ClustersModel>('');
  options: ClustersModel[];
  items: ClustersModel[] = [];
  item: ClustersModel;
  filteredOptions: Observable<ClustersModel[]>;
  distinctCluster: ClustersModel[];
  //regexPattern = "^(\d{1,4}+(-\d{1,4}+)?(,|$))+$";
  //regexPattern = "^(\d+(-\d+)?(,|$))+$";
  
  

  constructor(private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private reqModel: RequestModel,
    public reqDefinitionModel: RequestDefinitionsModel,
    private userModel: userModel,
    private loginModel: LoginModel,
    private clusterModel: ClustersModel,
    private clusterService: VlanAndClusterService) { }

  ngOnInit(): void {
    if (window.history.state.reqData != undefined) {
      this.vLanAndClusterForm = this.formBuilder.group({
        isCluster: [null, Validators.required],
        clusterControl: [''],
        rackmountControl: [''],
        vlanNumbers: ['', [Validators.required, Validators.pattern(/^(\d+(-\d+)?(,|$))+$/)]]
      });
      this.reqDefinitionModel = window.history.state.reqDefinitions;
      this.userModel = window.history.state.userModel;
      this.reqModel = window.history.state.reqData;
      this.loginModel = window.history.state.loginModel;

      this.vLanAndClusterNote = "Enter a single VLAN number. When multiple VLANs need to be added, separate them by using a comma ( , ). If you require a range of VLANs, then you can shorten the list by using a minus ( - ) between starting and ending VLAN number.";

      //this.vLanAndClusterForm.get('vlanNumbers').setValidators(Validators.required);
      this.clusterService.GetVMClusters().then(data => {
        this.options = data.table;
        this.distinctCluster = data.table1;
      });
      
      // const uniqueArray = this.options.filter((value, index, self) =>
      //   index === self.findIndex((t) => (
      //     t.clusterName === value.clusterName
      //   )));
      // console.log('distinctCluster - ' + uniqueArray);

    }
  }

  ngAfterViewInit(): void {
    
    this.vLanAndClusterForm.get('isCluster').setValue('1');
    this.bindCluster();
  }

  get validated() {
    return this.vLanAndClusterForm.controls;
  }

  bindCluster() {    
    this.filteredOptions = this.clusterControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.clusterName;
        return name ? this._filterCluster(name as string) : this.distinctCluster.slice();
      }),
    );
  }

  private _filterCluster(value: string): ClustersModel[] {
    const filterValue = value.toLowerCase();
    return this.distinctCluster.filter(option => option.clusterName.toLowerCase().includes(filterValue));
  }

  bindRackmount() {
    this.filteredOptions = this.rackmountControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.rackmountEnclosure;
        return name ? this._filterRackmount(name as string) : this.options.slice();
      }),
    );
  }

  private _filterRackmount(value: string): ClustersModel[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.rackmountEnclosure.toLowerCase().includes(filterValue));
  }


  ConditionalValidator() {
    if (this.vLanAndClusterForm.get('isCluster').value == '1') {
      this.vLanAndClusterForm.get('clusterControl').setValidators(Validators.required);
      //this.vLanAndClusterForm.get('vlanNumbers').setValidators(Validators.required);
      this.vLanAndClusterForm.get('rackmountControl').clearValidators();
      this.rackmountControl.reset();
      this.bindCluster();
    }
  }
  ChangeisVlanOrCluster() {
    if (this.vLanAndClusterForm.get('isCluster').value == '2') {
      this.vLanAndClusterForm.get('rackmountControl').setValidators(Validators.required);
      //this.vLanAndClusterForm.get('vlanNumbers').setValidators(Validators.required);
      this.vLanAndClusterForm.get('clusterControl').clearValidators();
      this.clusterControl.reset();
      this.bindRackmount();
    }
  }

  GoToPreviousView() {
    this.sharedService.gotoNextView('reqView', this.reqModel, this.reqDefinitionModel, this.userModel, this.loginModel);
  }

  GoToNextView() {
    this.submitted = true;
    if (this.vLanAndClusterForm.invalid) {
      return;      
    }
    if (this.vLanAndClusterForm.value.isCluster == undefined) {
      //console.log('cal 1 - ' + this.vLanAndClusterForm.value.isCluster);
      return;
    }
    if (this.vLanAndClusterForm.value.isCluster == '1' && this.selectedClusterName == '') {
      //console.log('cal selectedClusterName - ' + this.selectedClusterName);
      return;
    }
    if (this.vLanAndClusterForm.value.isCluster == '2' && this.selectedRackmountName == '') {
      //console.log('cal 3 - ' + this.selectedRackmountName);
      return;
    }
    // if (this.vLanAndClusterForm.value.vlanNumbers == '') {
    //   //this.vLanNumbers = this.vLanAndClusterForm.value.vlanNumbers;
    //   //console.log('cal vlanNumbers - ' + this.vLanNumbers);      
    //   return;
    // }


    this.reqModel.longDescription = 'Requested to add VLAN ' + this.vLanAndClusterForm.get('vlanNumbers').value + ' to Cluster ' + this.selectedClusterName + ' having rackmounts ' + this.selectedRackmountName + '.';
    console.log(this.reqModel.longDescription);
    this.sharedService.gotoNextView('sendRequestView', this.reqModel, this.reqDefinitionModel, this.userModel, this.loginModel);
  }

  displayFn(clustersModel: ClustersModel): string {
    return clustersModel && clustersModel.clusterName ? clustersModel.clusterName : '';
  }

  displayFn2(clustersModel: ClustersModel): string {
    return clustersModel && clustersModel.rackmountEnclosure ? clustersModel.rackmountEnclosure : '';
  }

  selectClusterOption(e: MatAutocompleteSelectedEvent) {
    this.selectedClusterName = typeof this.clusterControl.value === 'string' ? this.clusterControl.value : this.clusterControl.value?.clusterName;
    //this.options.filter()
    const resultNo = this.options.filter((obj: { ['clusterName']: any; }) => {
      return obj['clusterName'] === this.selectedClusterName;
    });
    resultNo.forEach(itm => {
      if (this.selectedRackmountName == '') {
        this.selectedRackmountName = itm.rackmountEnclosure;
      }
      else {
        this.selectedRackmountName = this.selectedRackmountName + ',' + itm.rackmountEnclosure;
      }
    });
    //console.log('resultNo - ' + this.selectedRackmountName);
  }
  selectRackmountOption(e: MatAutocompleteSelectedEvent) {
    //console.log(e.option.value.clusterName);
    this.selectedRackmountName = typeof this.rackmountControl.value === 'string' ? this.rackmountControl.value : this.rackmountControl.value?.rackmountEnclosure;
    this.selectedClusterName = e.option.value.clusterName;
    //console.log(this.selectedRackmountName);
  }
}
