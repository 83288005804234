<div class="item-container">
<div class="request-title">{{requestTitle}}</div>
<div class="button-tab">
    <button  class="button-pattern" (click)="btnClick();" >BACK TO MENU</button>
  <button type="button" id="create_request" class="button-pattern"
   (click) = "btnCreaterequestClick();"  >CREATE REQUEST</button>  
</div>
<div *ngIf="extraMsg!=''" class="extra-msg">{{extraMsg}}</div>
<div class="tabpanel">
    
    <div class="links-tab">          
        <button class="link-tab" onClick="document.getElementById('description').scrollIntoView();">Description</button>
        <button class="link-tab" onClick="document.getElementById('output').scrollIntoView();">Output</button>
        <button class="link-tab" onClick="document.getElementById('exclusions').scrollIntoView();">Exclusions</button>
        <button class="link-tab" onClick="document.getElementById('delivery').scrollIntoView();">Delivery</button>
        <button class="link-tab" onClick="document.getElementById('escalation').scrollIntoView();">Escalation</button>
   </div>
    
    <cdk-virtual-scroll-viewport id="scroll-tab" itemSize="50" >      
        <div class="detail-panel">  
    <div id="description" class="head-pannel">
        <p class="head-link">Description</p>
        <p class="head-val">What is this service about?</p>        
        <div class="info" id="div_img" ></div>        
    </div>
    <div id="output" class="head-pannel">
        <p class="head-link">Output</p>
        <p class="head-val">What is the output of this service?</p>
        <div class="info" [innerHtml]="output"></div>
    </div>
    <div id="exclusions" class="head-pannel">
        <p class="head-link">Exclusions</p>
        <p class="head-val">Are there any exclusions?</p>
        <div class="info" [innerHtml]="exclusions"></div>
    </div>
    <div id="delivery" class="head-pannel">
        <p class="head-link">Delivery</p>
        <p class="head-val">How do I get this service?</p>
        <div class="info" [innerHtml]="delivery"></div>
    </div>
    <div id="escalation" class="head-pannel">
        <p class="head-link">Escalation</p>        
        <p class="head-val">When and how do I escalate?</p>
        <div class="info" [innerHtml]="escalationHead"></div>
        <div class="info" [innerHtml]="escalation"></div>
        <div class="info" [innerHtml]="escalationData"></div>
    </div>    
</div>
      </cdk-virtual-scroll-viewport>       
      <div class="button-tab-btm">
    <button  class="button-pattern-btm" (click)="btnClick();">BACK TO MENU</button>
  <button type="button" id="create_request_btm" class="button-pattern-btm" (click) = "btnCreaterequestClick();" >CREATE REQUEST</button>  
  <button *ngIf="!isProd" type="button" id="migration_test" class="button-pattern-btm" (click) = "btnMigrationClick();" >{{migrationTestBtn}}</button>  
</div>  
    
</div>
</div>

