import { Injectable } from "@angular/core";
@Injectable()
export class RequestDefinitionsModel {
	id: number;
	itmId: string;
	parentId: string;
	ttLmin: number;
	ttLmax: number;
	helpText: number;
	deliveryDateNote: string;
	oneTimeAllowed: Boolean;
	allowedRequestors: string;
	description: string;
	hasForm: Boolean;
	needApproval: Boolean;
	formFile: string;
	waterMark: string;
	approvers: string;
	allowedEditors: string;
	isShow: Boolean;
	sendTo: string;
	sendCC: string;
	deliveryDateNoteMore: string;
	pI_Responsible: number;
	askBudgetCode: boolean;
	howToGet: string;
	output: string;
	exclusions: string;
	escalation: string;
	technicalManager: number;
	requestAcceptance: Boolean;
	hasLongDesc: boolean;
	hasProjectBudget: boolean;
	enableRelatedServices: boolean;
	AskBudgetCode: boolean;
	fileExtension: string;
	doReqDelDate: boolean;
	doAttach: boolean;
	doCluster: boolean;
}