import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { SharedService } from '../Shared/shared.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RequestModel } from '../model/request.model';
import { RequestDefinitionsModel } from '../model/requestDefinitions.model';
import { userModel } from '../model/user.model';
import { LoginModel } from '../model/login.model';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-proj-ref-and-budget-view',
  templateUrl: './proj-ref-and-budget-view.component.html',
  styleUrls: ['./proj-ref-and-budget-view.component.css'],
  providers: [RequestModel, RequestDefinitionsModel, userModel, LoginModel]
})
export class ProjRefAndBudgetViewComponent implements OnInit {
  //reqModel:RequestModel;
  projectRefAndBudgetForm: UntypedFormGroup;
  submitted = false;
  requestViewData: [any];
  relatedProjectText: string;
  askBudgetCode: boolean = false;
  isValidBudgetCode: boolean = false;
  projectReferenceNote: string = "";
  scopeOfErrProjectnote: string = "";
  budgetcodeNote: string = "";
  constructor(private SpinnerService: NgxSpinnerService,
    private formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private reqModel: RequestModel,
    private reqDefinitionModel: RequestDefinitionsModel,
    private userModel: userModel,
    private loginModel: LoginModel,
    private cookieService: CookieService,
    private router: Router) { }

  ngOnInit() {
    if (window.history.state.reqData != undefined) {
      this.projectRefAndBudgetForm = this.formBuilder.group({
        isPartOfProject: [null, Validators.required],
        IsExistingEricssonProject: [null, Validators.required],
        projectNameRequest: ['', Validators.required],
        projectID: ['', Validators.required],
        ericssonProject: [null, Validators.required],
        isBudgetcode: [null, Validators.required],
        budgetCode: ['']
      });
      this.reqDefinitionModel = window.history.state.reqDefinitions;
      this.userModel = window.history.state.userModel;
      this.reqModel = window.history.state.reqData;
      this.loginModel = window.history.state.loginModel;
      this.askBudgetCode = this.reqModel.askBudgetCode;
      this.ConditionalValidator();
      var email = this.cookieService.get('userEmail'); //this.ISTCookieValue.substring(this.ISTCookieValue.indexOf("username"), this.ISTCookieValue.indexOf("&")).replace("username=", '');
      console.log('email', email);
      let companyName = email.substring(email.indexOf('@') + 1, email.indexOf('.com'))

      this.relatedProjectText = "Please give the name/id of the related " + companyName + " project:";
    }
    else {
      this.router.navigate(['/createNewRequest']);
    }

    this.projectReferenceNote = "The information within this panel will be used for correct budget allocation and improved communication/aligned planning.";
    this.budgetcodeNote = "If you Then have a budget code, For example your changepoint And/Or WBS code, that Is related To this ISR request, Select yes.";
    this.scopeOfErrProjectnote = "If this new request is covered within the scope/offer of the specified Ericsson project, then choose ‘yes’ to ensure single billing.";
  }
  get validated() { return this.projectRefAndBudgetForm.controls; }

  ConditionalValidator() {
    /* this.projectRefAndBudgetForm.get('isPartOfProject').valueChanges
     .subscribe(value => {
       if(value=='Yes') {
         this.projectRefAndBudgetForm.get('IsExistingEricssonProject').setValidators(Validators.required)              
       } else {
         this.projectRefAndBudgetForm.get('IsExistingEricssonProject').clearValidators();
         this.projectRefAndBudgetForm.get('projectNameRequest').clearValidators();
           this.projectRefAndBudgetForm.get('projectID').clearValidators();
           this.projectRefAndBudgetForm.get('ericssonProject').clearValidators(); 
       }     
     });
     this.projectRefAndBudgetForm.get('IsExistingEricssonProject').valueChanges
     .subscribe(value => {
       if(value=='Yes') {
         this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
         this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);
         this.projectRefAndBudgetForm.get('ericssonProject').setValidators(Validators.required);
       }          
         else if(value=='No') {        
           this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
           this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);  
           this.projectRefAndBudgetForm.get('ericssonProject').clearValidators(); 
         }      
     });*/

    if (this.projectRefAndBudgetForm.get('isPartOfProject').value == 'Yes') {
      this.projectRefAndBudgetForm.get('IsExistingEricssonProject').setValidators(Validators.required)

      if (this.projectRefAndBudgetForm.get('IsExistingEricssonProject').value == 'Yes') {
        this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('ericssonProject').setValidators(Validators.required);
      }
      else {
        this.projectRefAndBudgetForm.get('projectNameRequest').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('projectID').setValidators(Validators.required);
        this.projectRefAndBudgetForm.get('ericssonProject').clearValidators();
      }

    } else {
      this.projectRefAndBudgetForm.get('IsExistingEricssonProject').clearValidators();
      this.projectRefAndBudgetForm.get('projectNameRequest').clearValidators();
      this.projectRefAndBudgetForm.get('projectID').clearValidators();
      this.projectRefAndBudgetForm.get('ericssonProject').clearValidators();
    }

    this.projectRefAndBudgetForm.get('IsExistingEricssonProject').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('projectNameRequest').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('projectID').updateValueAndValidity();
    this.projectRefAndBudgetForm.get('ericssonProject').updateValueAndValidity();

    if (this.askBudgetCode) {
      this.projectRefAndBudgetForm.get('isBudgetcode').setValidators(Validators.required);
    }
    else {
      this.projectRefAndBudgetForm.get('isBudgetcode').clearValidators();
    }
    this.projectRefAndBudgetForm.get('isBudgetcode').updateValueAndValidity();
  }
  ChangeisPartOfProject() {
    if (this.projectRefAndBudgetForm.get('isPartOfProject').value == 'No') {
      this.projectRefAndBudgetForm.get('IsExistingEricssonProject').setValue(null);
      this.projectRefAndBudgetForm.get('projectNameRequest').setValue('');
      this.projectRefAndBudgetForm.get('projectID').setValue('');
      this.projectRefAndBudgetForm.get('ericssonProject').setValue(null);
    }
    this.ConditionalValidator();
  }
  ChangeIsExistingEricssonProject() {
    // if(this.projectRefAndBudgetForm.get('IsExistingEricssonProject').value=='No')  {     
    this.projectRefAndBudgetForm.get('ericssonProject').setValue(null);
    //this.ConditionalValidator();
    //  }
    this.ConditionalValidator();
  }
  GoToNextView() {
    this.submitted = true;
    if (this.askBudgetCode && this.projectRefAndBudgetForm.value.isBudgetcode == 'Yes' &&
      (this.projectRefAndBudgetForm.value.budgetCode == null || this.projectRefAndBudgetForm.value.budgetCode.trim() == '')) {
      this.isValidBudgetCode = false;
      return;
    }
    else { this.isValidBudgetCode = true; }
    if (this.projectRefAndBudgetForm.invalid) {
      return;
    }
    else {
      if (this.projectRefAndBudgetForm.value.isPartOfProject == 'Yes')
        this.reqModel.isPartOfProject = true;
      else
        this.reqModel.isPartOfProject = false;

      this.reqModel.projectNameRequest = this.projectRefAndBudgetForm.value.projectNameRequest;
      this.reqModel.projectID = this.projectRefAndBudgetForm.value.projectID;

      if (this.projectRefAndBudgetForm.value.ericssonProject == 'Yes')
        this.reqModel.ericssonProject = true;
      else
        this.reqModel.ericssonProject = false;

      /*if(   this.projectRefAndBudgetForm.value.budgetCode.trim('')!=undefined && this.projectRefAndBudgetForm.value.budgetCode.trim('')!=null &&
      this.projectRefAndBudgetForm.value.budgetCode.trim('')!='') 
            this.reqModel.customerBudgetProjectCode=this.projectRefAndBudgetForm.value.budgetCode.;  
        else*/
      this.reqModel.customerBudgetProjectCode = this.projectRefAndBudgetForm.value.budgetCode;

      //console.log('modeltest',this.reqDefinitionModel);       

      this.sharedService.gotoNextView('sendRequestView', this.reqModel, this.reqDefinitionModel, this.userModel, this.loginModel);
    }
  }
  GoToPreviousView() {
    this.sharedService.gotoNextView('reqView', this.reqModel, this.reqDefinitionModel, this.userModel, this.loginModel);
  }


}
